<template>
  <div class="mod-role">
     <el-card style="margin-top:10px">
      <div>
        <span class="yuan">角色管理</span>
      </div>
      <div class="heng"></div>
      <div style="margin-top:30px">角色必须选择所属角色组和权限，如果角色有角色管理的权限则可以创建一个新的角色，新角色的父级不能大于等于当前角色。</div>
      <el-button  type="primary" @click="addOrUpdateHandle()" style="margin-top:20px;margin-bottom:20px">添加</el-button>
    <el-table
      :data="dataList"
      border
       :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="roleNo"
        header-align="center"
        align="center"
        width="120"
        label="角色ID">
      </el-table-column>
      <el-table-column
        prop="parentName"
        header-align="center"
        align="center"
        label="角色所属父级">
      </el-table-column>
      <el-table-column
        prop="roleName"
        header-align="center"
        align="center"
        label="角色名称">
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        width="180"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="createByName"
        header-align="center"
        align="center"
        width="180"
        label="创建人">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button  type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">{{scope.row.editType == 0 ? '编辑': ''}}</el-button>
          <el-button  type="text" size="small" @click="deleteHandle(scope.row)">{{scope.row.editType == 0 ? '删除': ''}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
       @size-change="sizeChangeHandle"
       @current-change="currentChangeHandle"
       :current-page="currPage"
       :page-sizes="[10, 20, 50, 100]"
       :page-size="pageSize"
       :total="totalCount"
       class="text_center"
       layout="total, sizes, prev, pager, next, jumper"
       style="text-align:center;margin-top:30px"
    ></el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <!-- <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update> -->
    <el-dialog :close-on-click-modal="false" :visible.sync="dialogFormVisible">
      <div slot="title" class="header-title">
            <span class="title-name" style="font-size:18px">{{ name }}</span>
        </div>
      <el-form :model="ruleForm" :rules="dataRule" ref="ruleForm" @keyup.enter.native="dataFormSubmit()" label-width="130px">
      <el-form-item label="角色所属父级">
        <el-select v-model="ruleForm.commercialName" @change="searchSelect">
          <el-option
            v-for="item in businessAffairsList"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="ruleForm.roleName" placeholder="角色名称"></el-input>
      </el-form-item>
      <el-form-item label="权限" style="display:inline-block">
        <el-checkbox-group v-model="checked1" @change="searched">
          <el-checkbox label="选择全部" name="type" :disabled="isShow"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item style="display:inline-block">
        <el-checkbox-group v-model="checked2" @change="searched1(checked2)">
          <el-checkbox label="展开全部" name="type" :disabled="isShow"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item size="mini">
        <el-tree
          v-if="refreshTable"
          :data="menuList"
          :props="menuListTreeProps"
          node-key="id"
          ref="menuListTree"
          :default-expand-all="expands"
          show-checkbox>
        </el-tree>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible1"
      width="30%"
      style="margin-top:5%"
      >
      <div style="transform:translateY(-30px)">
           <!-- <img src="../../../assets/img/12.png" alt="" style="transform:translateY(-3px)"> -->
           <span style="font-size:20px;font-weight:600;color:#7e7e7e;margin-left:10px;">删除成功</span> 
        </div>
      <div style="text-align:center;margin-bottom:40px;font-size:20px">角色已删除成功!</div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible2"
      width="40%"
      style="margin-top:5%"
      >
      <div style="transform:translateY(-30px)">
           <!-- <img src="../../../assets/img/12.png" alt="" style="transform:translateY(-3px)"> -->
           <span style="font-size:20px;font-weight:600;color:#ff5050;margin-left:10px;">删除失败</span> 
        </div>
      <div style="margin-bottom:40px;font-size:16px;margin:0 40px 40px 40px">{{ msg }}</div>
    </el-dialog>
    </el-card>
    
  </div>
</template>

<script>
import {sonRoles,roleInfo,dataFormSubmit,getDataListd,deleateHandle} from  "@/api/enterprise.js";
import { treeDataTranslate } from '@/utils'
//   import { added ,dda,dataFormSubmit2,sonRoles } from '../../../api/api'
//   import AddOrUpdate from './role-add-or-update'
//   import { getDataListd ,deleateHandle } from '../../../api/api'
  export default {
    data () {
      return {
        dialogVisible1:false,
        dialogVisible2:false,
        dataForm: {
          userName: ''
        },
        ruleForm:{
          id: 0,
          roleName: '',
          commercialName:'',//角色所属父级
          type:[]
        },
        businessAffairsList:[],
        menuList: [],
        menuListTreeProps: {
          label: 'name',
          children: 'children'
        },
        name:'',
        msg:'',
        isShow:true,
        refreshTable:true,
        checked1:false,
        checked2:false,
        expands:false,
        dataRule: {
          roleName: [
            { required: true, message: '角色名称不能为空', trigger: 'blur' }
          ]
        },
        id1:'',
        ided:'',
        dataList: [],
        // 分页属性
        pageSize: 10,
        totalPage: 0,
        totalCount: 0,
        currPage: 1,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: true,
        dialogFormVisible:false,
        tempKey: -666666 // 临时key, 用于解决tree半选中状态项不能传给后台接口问题. # 待优化
      }
    },
    components: {
      // AddOrUpdate
    },
    created(){
      this.sonRolesed()
    },
    mounted(){
      this.getDataList()
      this.sonRolesed()
    },
    activated () {
      
    },
    methods: {
      //弹框获取角色信息 --新增
      submit(){
        let params ={
          roleId:this.id1
        };
        roleInfo(params).then((data) => {
          if(data.code == 200) {
            this.menuList = treeDataTranslate(data.data.menuList,'id')
            var idx = data.data.menuIdList.indexOf(this.tempKey)
            if(idx != -1) {
              data.data.menuIdList.splice(idx,data.data.menuIdList.length - idx)
            }
          }
        })
      },
      //弹框获取角色信息 --修改
      submited(id){
        this.isShow = false
        let params ={
          roleId:id
        };
        roleInfo(params).then((data) => {
          console.log(data,'data');
          if(data.code == 200) {
            // this.menuList = treeDataTranslate(data.data.menuList,'id')
            this.ruleForm.roleName = data.data.roleName
            this.ruleForm.id = data.data.id
            this.ruleForm.commercialName = data.data.parentId
            var idx = data.data.menuIdList.indexOf(this.tempKey)
            if(idx != -1) {
              data.data.menuIdList.splice(idx,data.data.menuIdList.length - idx)
            }
            this.$refs.menuListTree.setCheckedKeys(data.data.menuIdList)
          }
          this.handleClick()
        })
        
      },
      handleClick(){
        let paramsd ={
          roleId:this.ruleForm.commercialName
        };
        roleInfo(paramsd).then((data) => {
          if(data.code == 200) {
            this.menuList = treeDataTranslate(data.data.menuList,'id')
            var idx = data.data.menuIdList.indexOf(this.tempKey)
            if(idx != -1) {
              data.data.menuIdList.splice(idx,data.data.menuIdList.length - idx)
            }
          }
        })
      },
      //获取角色下拉框
      sonRolesed(){
        sonRoles().then((data) => {
          if(data.code == 200) {
            this.businessAffairsList = data.data
          }
        })
      },
      //下拉选中
      searchSelect(value){
        this.ided = value
        if(value != '') {
           //下拉选中id
           this.id1 = value 
          this.isShow = false
          this.submit()
        }
      },
      //点击全选
      searched(){
        if(this.checked1) {
          this.$refs.menuListTree.setCheckedNodes(this.menuList)
        }else {
          this.$refs.menuListTree.setCheckedKeys([])
        }
      },
      //展开全部
      searched1(value){
        if(value == true) {
          this.refreshTable=false
          this.expands = true; 
           this.$nextTick(() => { 
             this.refreshTable=true   
            //  this.submit()
            });
        } //收起
        else if(value == false){
          this.refreshTable=false
            this.expands = false;
            this.$nextTick(() => {
                this.refreshTable = true;
                // this.submit()
            });
        }
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            let params = {
              'dataid':this.ruleForm.id ,
              'parentId': this.id1 || this.ruleForm.commercialName,
              'id':this.ruleForm.id ,
              'roleId': this.ruleForm.id || undefined,
              'roleName': this.ruleForm.roleName,
              'remark': this.ruleForm.remark,
              'menuIdList': [].concat(this.$refs.menuListTree.getCheckedKeys(), [this.tempKey], this.$refs.menuListTree.getHalfCheckedKeys())
            }
            dataFormSubmit(params).then((data) => {
              if (data && data.code === 200) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
                this.dialogFormVisible = false
                this.getDataList()

              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        let params = {
          'current':this.currPage,
          'size':this.pageSize,
          'roleName': this.dataForm.userName
        }
        getDataListd(params).then((data) => {
          if (data && data.code === 200) {
            this.dataList = data.data.list
            this.totalCount = data.data.totalCount
          } else {
            this.dataList = []
            this.totalCount = 0
          }
          this.dataListLoading = false
        }).catch(err => {
        })
      },
      // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },

    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.sonRolesed()
        this.menuList = []
        this.ruleForm.roleName = ''
        this.ruleForm.commercialName = ''
        this.dialogFormVisible = true
        if(id) {
          this.name = '修改角色'
          this.submited(id)
        }else {
          this.name = '添加角色'
        }
        
      },
      // 删除
      deleteHandle (row) {
        var ids = row.id ? [row.id] : this.dataListSelections.map(item => {
          return item.roleId         
        })
        this.$confirm(`确定对[${row.roleName}]进行删除操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleateHandle(ids).then((data) => {
            if (data && data.code === 200) {
              this.dialogVisible1 = true
                this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else if(data.code == 320102 || data.code == 320101){
              this.dialogVisible2 = true
              this.msg = '当前角色下已经存在用户或已存在子级角色，不可删除该角色，删除该角色的分配和该角色下的子级角色后，才可删除该角色！'
             }
          })
         }) .catch(err =>{})
      }
    }
  }
</script>
<style scoped>
.yuan {
  font-weight: 600;
  color: #6a6a6a;
  font-size: 14px;
  margin-left:15px
}
.heng {
  height: 1px;
  margin-top: 10px;
  background-color: rgb(223, 213, 213);
  overflow: hidden;
}
</style>